<template>
  <div>
    <div class="head flex justify-between laptop:p-3 desktop:p-4 mobile:p-2 p-2 mt-1" 
      style="border-top-left-radius: 10px;border-top-right-radius: 10px; margin-bottom: 0;"
      >
      <div class="left align-middle" :style="{fontSize:screenType.isMobileScreen ?'0.6rem' :'15px'}">{{ $t("common.jettySummary") }}</div>
      <div class="right align-middle" :style="{fontSize:screenType.isMobileScreen ?'0.5rem' :'12px'}">
        <div>
          <span class="pr-2">{{ $t("common.cardView") }} </span>
          <el-switch
            active-color="#2698B1"
            v-model="switchMapView"></el-switch>
          <span class="pl-2">{{ $t("common.mapView") }} </span>
          <el-button style="margin-left: 16px" size="small" @click="updateList">
            <i :class="unassignedListLoading && useBerthLoading ? 'el-icon-refresh rotating' : 'el-icon-refresh'"></i>
          </el-button>
          <!-- <div>{{ count }}</div> -->
        </div>
      </div>
    </div>
    <div class="content">
      <div v-if="!switchMapView" style="padding-bottom: 10px;">
        <div class="tabCard w-full overflow-x-auto" style="background-color: rgba(255, 255, 255, 0.5);">
          <div class="item-tab-card desktop:ml-4 laptop:ml-2" :class="screenType.isMobileScreen ?'company-card-mobile' :'company-card'" v-for="item in companyList" :key="'tab-view-customer-' + item.companyName" @click="onhandleJettyList(item)">
            <span class="truncate">{{ item.companyName }}</span>
            <span class="tag">{{ item.num }} </span>
          </div>
        </div>
        <div class="cardList w-full flex flex-wrap sm:justify-start mobile:justify-start gap-1" 
          style="border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;background-color: rgba(255, 255, 255, 0.5);"
        >
          <div class="item desktop:ml-4 desktop:mb-4 laptop:ml-2.5 laptop:mb-2.5 mobile:mb-2" v-bind:class="{'screen-mobile': screenType.isMobileScreen}" v-for="(item, key) in jettyList" :key="'card-view-berth-' + key"  :style="{width:screenType.isMobileScreen?`${Math.floor(screenType.mainLayoutWidth/2 -14)}px`:'260px'}">
            <div class="address w-full flex items-center" >
              <i class="el-icon-location-outline text-2xl mobile:text-base" />
              <span class="ml-1 mobile:text-[8px]">Berth {{ key }}</span>
            </div>
            <div
              class="content w-full"
              v-if="item[0]"
              @click="handleBerthItemClick($event, item[0])"
            >
              <div class="flex justify-between">
                <div class="flex items-center">
                  <span class="dot"></span>
                  <span class="number mobile:text-xs">{{ item[0]?.berthAssignTiming }} </span>
                </div>
                <div class="close" @click.stop="handleResetClick(item[0], key)">
                  <span class="symbol"></span>
                </div>
              </div>
              <p class="desc">
                {{ item[0]?.vesselName }}
              </p>
              <p class="desc">{{ item[0]?.companyName }}</p>
              <!--div class="btn">
                AIS Timing:
                <span class="font-bold">{{ item[0]?.updateTime }} </span>
              </div-->
            </div>
            <div class="content w-full mt-0" v-else>
            </div>
            <div
              v-if="item[1]"
              class="content w-full mt-0"
              @click="handleBerthItemClick($event, item[1])"
              style="
                margin-top: 0;
                border-top: 1px solid rgba(130, 136, 156, 0.2);
              "
            >
              <div class="flex justify-between">
                <div class="flex items-center">
                  <span class="dot"></span>
                  <span class="number">{{ item[1]?.berthAssignTiming }} </span>
                </div>
                <div class="close" @click="handleResetClick(item[1], key)">
                  <span class="symbol"></span>
                </div>
              </div>
              <p class="desc">{{ item[1]?.vesselName }}</p>
              <p class="desc">{{ item[1]?.companyName }}</p>
              <!--div class="btn">
                AIS Timing:
                <span class="font-bold">{{ item[1]?.updateTime }} </span>
              </div-->
            </div>
            <div
              class="content w-full mt-0"
              style="
                margin-top: 0;
                border-top: 1px solid rgba(130, 136, 156, 0.2);
              "
              v-else
            >
            </div>
           
          </div>
        </div>
      </div>
      <map-view
        :jettyData="jettyList"
        v-else
        ref="mapView"
        v-show="switchMapView" 
        style="margin-bottom: 10px;"
      />
      <div style="padding: 10px; border-radius: 10px; background-color: white;">
        <div
          class="tableList flex justify-between items-center"
          v-bind:class="{'tableList-mobile':screenType.isMobileScreen}"
          style="border-bottom: 1px solid rgba(130, 136, 156, 0.2)"
          :style="{marginTop: (switchMapView && screenType.isMobileScreen)?'-150px':0}"
        >
          <span> {{ $t("common.ubn") }}</span>
          <el-button type="primary" @click="viewAllclick" :size="screenType.isMobileScreen?'mini':'small'"
            >{{ $t("btn.viewAll") }}
          </el-button>
        </div>
        <div>
          <created
            ref="barging"
            :orderList="tableData"
            @updateList="updateList"
            :orderIndex="1"
            :isShowTopinpBar="false"
            @focused="handleStopRefresh"
            @blurred="handleStartRefresh"
          />
        </div>
      </div>
    </div>
    <!-- 查看弹窗， unused dialog，bargeclick(unused) to change dialogVisible -->
    <el-dialog
      title="Ship AIS Information"
      :visible.sync="dialogVisible"
      width="700px"
    >
      <ship-tab-view :shipinfo="shipinfo" :vesselName="vesselName" />
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import SvgIcon from "@/components/SvgIcon.vue";
import MapView from "./map-view.vue";
import { getCompanyList, getUseBerth, resetBerth, resetBerthReason } from "@/services/index";
import { getClientList, getSelectVessel } from "@/services/user";
import Created from "@/view/certificate/BargingNomination/Created.vue";
import lodash from "lodash";
import ShipTabView from "@/view/barging/components/ship-tab-view.vue";
import { mapState } from "vuex";
import { JETTY_LIST } from '@/utils/constants.js'
export default {
  components: { SvgIcon, MapView, ShipTabView, ShipTabView ,Created},
  data() {
    return {
      switchMapView: false,
      // switchMapView: true,
      tableData: [],
      companyList: [],
      jettyData: [],
      listLoading: false,
      dialogVisible: false,
      jettyList: JETTY_LIST,
      shipinfo: {},
      vesselName: "",
      bargeoptions: [],
      refreshInterval: null,
      useBerthSource: null,
      useBerthLoading: false,
      unassignedListSource: null,
      unassignedListLoading: false,
      count: 0,
      countInterval: null
    };
  },
  computed: {
    ...mapState(["screenType"]),
 
  },
  watch: {},
  mounted() {
    this.handleStartRefresh();

    this.updateList();
    this.getCompanyList();
    this.getData();
  },
  methods: {
    handleStartRefresh() {
      this.handleStopRefresh();

      this.countInterval = setInterval(() => {
        this.count += 1;
      }, 1000);

      this.refreshInterval = setInterval(() => {
        this.count = 0;
        this.updateList();
      }, 10000);
    },
    handleStopRefresh() {
      this.count = 0;

      if (this.useBerthSource) {
        this.useBerthSource.cancel();
        this.useBerthSource = null;
      }

      if (this.unassignedListSource) {
        this.unassignedListSource.cancel();
        this.unassignedListSource = null;
      }

      if (this.countInterval) {
        clearInterval(this.countInterval);
      }

      if (this.refreshInterval) {
        clearInterval(this.refreshInterval);
      }
    },
    getData() {
      getSelectVessel().then((res) => {
        if (res.code == 200) {
          this.bargeoptions = res.data;
        }
      });
    },
    updateList(){
      if (this.useBerthSource) {
        this.useBerthSource.cancel();
      }
      const CancelToken2 = axios.CancelToken;
      this.useBerthSource = CancelToken2.source();

      if (this.unassignedListSource) {
        this.unassignedListSource.cancel();
      }
      const CancelToken1 = axios.CancelToken;
      this.unassignedListSource = CancelToken1.source();

      this.getUseBerthList(this.useBerthSource.token);
      this.getUnassignedList(this.unassignedListSource.token)
    },
    resetData() {
      this.jettyList = {...JETTY_LIST};
    },
    getCellStyle({ rowIndex }) {
      if (rowIndex % 2 === 1) {
        return {
          backgroundColor: "rgba(255,255,255,0.2)",
        };
      } else {
        return {
          backgroundColor: "rgba(228,228,228,0.2)",
        };
      }
    },
    handleBerthItemClick(event, item) {
      // debugger
      if (event.target.classList.contains("close")) return;
      this.$router.push({
        path: "/barging/index",
        query: {
          id: item.bId,
          pageStatus: 1,
        },
      });
    },
    // 码头点击
    viewAllclick() {
      if (
        this.$store.state.userInfo.userType == 1 ||
        this.$store.state.userInfo.userType == 4 ||
        this.$store.state.userInfo.userType == 0
      ) {
        this.$router.push({
          path: "/barging/index",
          query: {
            pageStatus: 0,
          },
        });
      }
      sessionStorage.setItem("pageStatus", 0);
    },
    // 获取码头使用情况
    getUseBerthList(cancelToken) {
      this.useBerthLoading = true;

      getUseBerth(cancelToken).then((res) => {
        let data = res.data;
        let newData = lodash.groupBy(data, "berthName");
        this.jettyData = newData;
        const occupiedBerthKey = [];
        Object.entries(this.jettyData).forEach(([key, val]) => {
            this.jettyList[key] = val;
            occupiedBerthKey.push(key)
        });
        Object.entries(this.jettyList).forEach(([key, val]) => {
          if (!occupiedBerthKey.includes(key)) {
            this.jettyList[key] = [];
          }
        });
      }).finally(() => {
        setTimeout(() => {
          this.useBerthLoading = false;
        }, 2000);
      });
    },
    getUnassignedList(cancelToken) {
      this.unassignedListLoading = true;

      this.listLoading = true;
      getClientList(1, {}, null, null, null, null, cancelToken).then((res) => {
        let data = res.data.table["rows"];
        this.tableData = data;
        this.listLoading = false;
      }).finally(() => {
        setTimeout(() => {
          this.unassignedListLoading = false;
        }, 2000);
      });
    },
    Tanbleclick(row, column, event) {
      let target = event.target;
      if (!target.classList.contains("bargeNameLink")) {
        // this.$emit("Tanbleclick", row);
        this.$router.push({
          path: "/barging/index",
          query: {
            id: row.id,
            pageStatus: 1,
          },
        })
      }
    },
    getCompanyList() {
      getCompanyList().then((res) => {
        this.companyList = res.data
      });  
    },
    // unused funciton
    bargeclick(target) {
      this.dialogVisible = true;
      let result = this.bargeoptions.find(
        (item) => item.imo == target.bargeName
      );
      this.$nextTick(() => {
        this.shipinfo = result;
        this.vesselName = target.vesselName;
      });
    },
    handleResetClick(item, key) {
      this.handleStopRefresh();
      this.$prompt('Reason', 'Unassign Berth', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          inputType: 'textarea',
          inputPattern: /^(?!\s*$).+/,
          inputErrorMessage: 'Reason is required',
          closeOnClickModal: false
        }).then(({ value }) => {
          // Remove from berth list first
          lodash.remove(this.jettyList[key], function (_item) { return _item.bId == item.bId });
          resetBerthReason({bid: item.bId, remark: value}).then(() => {
            this.getUseBerthList();
            this.getUnassignedList();
            this.getCompanyList();
            this.handleStartRefresh();
          })
        }).catch(() => {
          this.handleStartRefresh();
        });
    },
  // eslint-disable-next-line no-unused-vars
  onhandleJettyList(company){
  }
  
  },
};
</script>

<style scoped lang="scss">
.head {
  background: #ffffff;
  box-shadow: 0px 4px 4px 1px rgba(196, 196, 196, 0.1);
  border-radius: 2px 2px 0px 0px;
  // padding: 18px 22px;
  margin-bottom: 6px;
  .left {
    font-weight: 700;
    line-height: 20px;
    color: #4c565c;
  }
  .right {
    font-weight: 300;
    line-height: 20px;
    color: #4c565c;
  }
}
.content {
  // background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 4px 1px rgba(196, 196, 196, 0.1);
  border-radius: 2px 2px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .tabCard {
    display: flex;
    background-color: rgba(255, 255, 255, 0.5);
    >div{
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #ffffff;
      box-shadow: 0px 4px 4px 2px rgba(195, 195, 195, 0.1);
      border-radius: 2px;
      
      margin-top: 7px;
      margin-bottom: 7px;
      line-height: 40px;
      color: #49454F;
      // margin-left: 17px;
      // height: 40px;
      // font-weight: 600;
      // font-size: 13px;
      // padding: 13px;
      .tag {
        line-height: 29px;
        text-align: center;
        color: #ffffff;
        background: #49454F;
        
      }
    
    }
     .company-card {
      height: 40px;
      line-height: 40px;
      font-size: 13px;
      padding-left: 13px;
      padding-right: 13px;
      margin-right: 17px;
      .tag{
        width: 29px;
        height: 29px;
        line-height: 29px;
        margin-left: 40px;
        font-size: 12px;
        border-radius: 10px;
      }
    }
   .company-card-mobile {
      height: 29px;
      line-height: 29px;
      font-size: 10px;
      padding-left: 8px;
      padding-right: 8px;
      margin-right: 10px;
       .tag{
        width: 17px;
        height: 14px;
        line-height: 14px;
        margin-left: 10px;
        font-size: 10px;
        border-radius: 10px;
      }
    }
  }
  .cardList {
  //  margin-top: 12px;
    .item {
      .address {
        width: 100%;
        height: 36px;
        padding: 7px 13px;
        background: white;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        font-weight: 500;
        font-size: 15px;
        color: #4c565c;
        box-shadow: 0px 4px 4px 2px rgba(196, 196, 196, 0.1);
      }
      
      .content {
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 4px 4px 2px rgba(196, 196, 196, 0.1);
        margin-top: 0;
        border-top: 1px solid #D5DDED;
        border-radius: 0px;
        padding: 12px;
        height: 133px;
        min-height: 133px;
        .dot {
          width: 11px;
          height: 11px;
          border-radius: 50%;
          background: $primaryColor;
        }
        .number {
          font-weight: 600;
          font-size: 13px;
          color: #4c565c;
          margin-left: 7px;
        }
        .desc {
          font-size: 12px;
          /* identical to box height */
          margin-top: 7px;
          color: #4c565c;
        }
        .btn {
          padding: 5px 9px;
          background: rgba(38, 152, 177, 0.15);
          color: #2698b1;
          font-size: 12px;
          border-radius: 50px;
          margin-top: 11px;
        }
        .close {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 17px;
          height: 17px;
          border: 1px solid #ff0e0e;
          border-radius: 50%;
          cursor: pointer;
          .symbol {
            width: 8px;
            height: 1px;
            background: #ff0e0e;
          }
        }
      }
      .content:last-child {
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
    .screen-mobile{

      .address{
        height: 22px;
        font-size: 8px;
      }
      .content {
        border-radius: 2px;
        padding: 4px 6px;
        height: 79px;
        min-height: 79px;
        .dot {
          width: 8px;
          height: 8px;
        }
        .number {
          font-size: 8px;
        }
        .desc {
          font-size: 9px;
          margin-top:3px;
        }
        .btn {
          padding: 2px 4px;
          font-size:8px;
          margin-top: 3px;
        }
        .close {
          width: 12px;
          height: 12px;
          cursor: pointer;
          .symbol {
            width: 6px;
          }
        }
      }
    
    }
  }
 
  .tableList {
    background: #ffffff;
    box-shadow: 0px 4px 4px 1px rgba(196, 196, 196, 0.1);
    border-radius: 2px 2px 0px 0px;
    margin-top: 20px;
    padding: 18px 22px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    color: #4c565c;
  }
  .tableList-mobile {
    margin-top: 8px;
    padding: 0 13px;
    font-weight: 700; 
    font-size: 8px;
    line-height: 28px;
    .el-button--mini{
    padding: 0;
    
    font-size: 8px;
    line-height: 16px;
    width: 58px;
    }
  }
}
::v-deep .el-table th.el-table__cell > .cell {
  font-size: 12px;
  color: #82889c;
}

.bargeNameLink {
  color: #285aff;
  font-size: 11px;
  text-decoration-line: underline;

  color: #285aff;
  cursor: pointer;
}
::v-deep .el-table__row > .el-table__cell > .cell {
  font-size: 11px;
}
::v-deep .Topinput{
  position: unset !important;
  padding-bottom: 10px;
}

</style>
